import {AttachmentFile} from '@hconnect/common/types'
import React from 'react'

import {InlineAttachmentsUpload} from '../../../../shared/components/attachments/InlineAttachmentsUpload'
import {useAttachmentFilesQuery} from '../../../../shared/hooks/api'
import {
  useAddMaterialOrderAttachments,
  useDeleteMaterialOrderAttachments
} from '../../../../shared/hooks/api/materialOrders'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import type {MaterialOrder, MaterialOrderAttachment} from '../../../../shared/interfaces/api'

interface MaterialOrderAttachmentSectionProps {
  isDisabled: boolean
  materialOrder?: MaterialOrder
}

export const MaterialOrderAttachmentSection: React.FC<MaterialOrderAttachmentSectionProps> = ({
  materialOrder,
  isDisabled
}) => {
  const plantCode = useUrlParam('plantCode')
  const {data: attachmentFiles, isFetching: areFilesFetching} = useAttachmentFilesQuery({
    attachments: materialOrder?.attachments ?? [],
    isPreview: true
  })
  const {mutateAsync: addMaterialOrderAttachments, isLoading: isAddingAttachment} =
    useAddMaterialOrderAttachments()
  const {mutateAsync: deleteMaterialOrderAttachments, isLoading: isDeletingAttachment} =
    useDeleteMaterialOrderAttachments()

  const handleAddAttachments = (attachemnts: AttachmentFile[]) => {
    if (!materialOrder || !attachemnts.length) {
      return
    }
    void addMaterialOrderAttachments({
      plantCode,
      materialOrderId: materialOrder.id,
      attachments: attachemnts
    })
  }

  const handleDeleteAttachments = (attachemnts: MaterialOrderAttachment[]) => {
    if (!materialOrder || !attachemnts.length) {
      return
    }
    void deleteMaterialOrderAttachments({
      plantCode,
      materialOrderId: materialOrder.id,
      attachmentIds: attachemnts.map(({id}) => id)
    })
  }

  const handleOrderAttachmentsChange = (attachments: AttachmentFile[]) => {
    const currentAttachmentIds =
      attachments.map(({id}) => id).filter((id) => id !== undefined) ?? []
    const attachmentsToAdd = attachments.filter((attachment) => attachment.id === undefined)
    const attachmentsToDelete =
      materialOrder?.attachments.filter(
        (attachment) => !currentAttachmentIds.includes(attachment.id)
      ) ?? []
    handleAddAttachments(attachmentsToAdd)
    handleDeleteAttachments(attachmentsToDelete)
  }

  const isLoading = isAddingAttachment || isDeletingAttachment
  const shouldDisableAttachmentUpload = isDisabled || isLoading
  return (
    <InlineAttachmentsUpload
      attachments={attachmentFiles ?? []}
      setAttachments={handleOrderAttachmentsChange}
      isDisabled={shouldDisableAttachmentUpload}
      isLoading={areFilesFetching}
    />
  )
}
